import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import './styles.scss';
import Onboard from './onboard/Onboard';
import Members from './members/Members';

const Documents = () => {
	const { t, i18n } = useTranslation();
	const currentLanguage = i18n.language;

	const applications = [
		{
			text: t('documents.applications.joiningAgreement'),
			href:
				currentLanguage === 'en'
					? 'https://drive.google.com/file/d/1OjODzSFphN2PCkM3rAZBQ_B68o_ro5tm/view?usp=sharing'
					: 'https://drive.google.com/file/d/1mdiO4hI5Dxypy_U-J5gM-M9L_6iNb3RO/view?usp=sharing',
			isPrimary: true,
		},
		{
			text: t('documents.applications.legalEntityApplication'),
			href:
				currentLanguage === 'en'
					? 'https://docs.google.com/document/d/1gjY_GfHo9sj2A9l3iM3N0P4C71N0ZXta/edit?usp=sharing&ouid=106378968670706272956&rtpof=true&sd=true'
					: 'https://docs.google.com/document/d/1acoFYOpanyZYz0X0OlkeRdYA7YZT75NH/edit?usp=sharing&ouid=106378968670706272956&rtpof=true&sd=true',
			isPrimary: false,
		},
		{
			text: t('documents.applications.individualApplication'),
			href:
				currentLanguage === 'en'
					? 'https://docs.google.com/document/d/1eUX1dNaxeFy944Kyxr_uztGB5H_EaWCE/edit?usp=sharing&ouid=106378968670706272956&rtpof=true&sd=true'
					: 'https://docs.google.com/document/d/150BAaj1nhd5VcV0DK3b_OUHA1dgmIwYQ/edit?usp=sharing&ouid=106378968670706272956&rtpof=true&sd=true',
			isPrimary: false,
		},
	];

	const sections = [
		{
			title: t('documents.sections.stockExchange'),
			id: 'stock',
			links: [
				{
					content: t('documents.links.stockRules'),
					href: 'https://drive.google.com/file/d/16C0Xv4ph81sGdhxi8tFnnCD-4o6VE34n/view?usp=sharing',
				},
			],
		},
		{
			title: t('documents.sections.currencyExchange'),
			id: 'currency',
			links: [
				{
					content: t('documents.links.currencyRules'),
					href: 'https://drive.google.com/file/d/1kbTAaynA711WFCZz0R8hM5bbvge81vkX/view?usp=sharing',
				},
			],
		},
		{
			title: t('documents.sections.commodityExchange'),
			id: 'commodity',
			links: [
				{
					content: t('documents.links.commodityTradingRules'),
					href: 'https://drive.google.com/file/d/1r-GP7oAtiuLvoMPoe6piXvUXNbXhLtcq/view?usp=sharing',
				},
				{
					content: t('documents.links.commodityApplication'),
					href: 'https://docs.google.com/document/d/1PWzfCGGdZJXPHo3WSgGkvVM51Lpv1LwP/edit?usp=sharing&ouid=106378968670706272956&rtpof=true&sd=true',
				},
			],
		},
		{
			title: t('documents.sections.cryptoExchange'),
			id: 'crypto',
			links: [
				{
					content: t('documents.links.cryptoRules'),
					href: 'https://drive.google.com/file/d/1XF4FEwq0xtDuW9Py94ccJAK0oTERHE0x/view?usp=sharing',
				},
			],
		},
		{
			title: t('documents.sections.listing'),
			id: 'listing',
			links: [
				{
					content: t('documents.links.listingRules'),
					href: 'https://drive.google.com/file/d/1dLD4shhaVBazHlOiqPpFgRpYfVyZg5BM/view?usp=sharing',
				},
			],
		},
	];

	const [activeTab, setActiveTab] = useState('stock');
	const handleTabChange = (id) => {
		setActiveTab(id);
	};

	return (
		<section className='documents'>
			<div className='container'>
				<h1 className='documents__title'>{t('documents.title')}</h1>
				<p className='documents__description'>{t('documents.description')}</p>
				<div className='documents__buttons'>
					{applications.map((item, index) => (
						<a
							key={index}
							target='_blank'
							rel='noopener noreferrer'
							href={item.href}
							className={`documents__buttons__button ${
								item.isPrimary ? 'documents__buttons__button--primary' : ''
							}`}
						>
							{item.text}
						</a>
					))}
				</div>
				<div className='documents__tabs'>
					{sections.map((section) => (
						<button
							key={section.id}
							className={`documents__tabs__tab ${
								activeTab === section.id ? 'documents__tabs__tab--active' : ''
							}`}
							onClick={() => handleTabChange(section.id)}
						>
							{section.title}
						</button>
					))}
				</div>
				<div className='documents__content'>
					{sections
						.find((section) => section.id === activeTab)
						?.links.map((link, index) => (
							<a
								key={index}
								target='_blank'
								rel='noopener noreferrer'
								href={link.href}
								className='documents__rules-link'
							>
								{link.content}
							</a>
						))}
				</div>

				<Onboard />
				<Members />
			</div>
		</section>
	);
};

export default Documents;
