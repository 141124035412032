import React, { useState, useEffect, useRef } from 'react';
import './styles.scss';
import { Link, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const Header = () => {
	const { t, i18n } = useTranslation();
	const [openDropdown, setOpenDropdown] = useState(null);
	const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
	const [isScrolled, setIsScrolled] = useState(false);
	const dropdownRef = useRef(null);
	const location = useLocation();

	const isHomePage = location.pathname === '/';

	const menuItems = [
		{
			title: t('header.themes'),
			dropdown: [{ text: t('header.news'), href: '/exchange-news' }],
		},
		{
			title: t('header.about_exchange'),
			dropdown: [
				{ text: t('header.representatives'), href: '/about' },
				{ text: t('header.documents'), href: '/documents' },
				{ text: t('header.listing'), href: '/listing' },
				{ text: t('header.financial_statements'), href: '/statement' },
			],
		},
	];

	useEffect(() => {
		const handleScroll = () => {
			const scrollPosition = window.scrollY;
			setIsScrolled(scrollPosition > 240);
		};

		window.addEventListener('scroll', handleScroll);
		return () => window.removeEventListener('scroll', handleScroll);
	}, []);

	const headerClass = `header ${isHomePage ? 'header--home' : ''} ${
		isScrolled ? 'header--scrolled' : ''
	}`;

	const toggleDropdown = (index) => {
		setOpenDropdown(openDropdown === index ? null : index);
	};

	const toggleMobileMenu = () => {
		setIsMobileMenuOpen(!isMobileMenuOpen);
		document.body.style.overflow = !isMobileMenuOpen ? 'hidden' : '';
	};

	const handleLinkClick = () => {
		setIsMobileMenuOpen(false);
		setOpenDropdown(null);
		document.body.style.overflow = '';
	};

	useEffect(() => {
		const handleClickOutside = (event) => {
			if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
				setOpenDropdown(null);
			}
		};

		document.addEventListener('mousedown', handleClickOutside);
		return () => {
			document.removeEventListener('mousedown', handleClickOutside);
		};
	}, []);

	const changeLanguage = (lang) => {
		i18n.changeLanguage(lang);
		document.documentElement.lang = lang;

		const descriptions = {
			en: 'Envoys Vision Digital Exchange - a platform for traders and investors offering a wide range of crypto assets and financial instruments',
			ru: 'Envoys Vision Digital Exchange - это платформа для трейдеров и инвесторов, которая предлагает широкий выбор криптоактивов и финансовых инструментов',
		};

		document
			.querySelector('meta[name="description"]')
			.setAttribute('content', descriptions[lang]);
	};

	return (
		<header className={headerClass}>
			<div className='container'>
				<div className='header__content'>
					<div className='header__logo'>
						<Link to='/'>
							<img
								src='/white-logo.svg'
								alt='logo'
								className='header__logo-image'
							/>
						</Link>
					</div>
					<button
						className={`header__mobile-toggle ${
							isMobileMenuOpen ? 'header__mobile-toggle--open' : ''
						}`}
						onClick={toggleMobileMenu}
					>
						<span className='header__mobile-toggle-icon'></span>
					</button>
					<nav
						className={`header__nav ${
							isMobileMenuOpen ? 'header__nav--open' : ''
						}`}
					>
						<ul className='header__nav-list'>
							{menuItems.map((item, index) => (
								<li key={index} className='header__nav-item' ref={dropdownRef}>
									<button
										className='header__nav-link'
										onClick={() => toggleDropdown(index)}
									>
										{item.title}
										<svg
											xmlns='http://www.w3.org/2000/svg'
											aria-hidden='true'
											role='img'
											className={`icon ml-1 mt-1 ${
												openDropdown === index ? 'icon--rotated' : ''
											}`}
											width='18px'
											height='18px'
											viewBox='0 0 24 24'
										>
											<path
												fill='currentColor'
												d='M7.41 8.59L12 13.17l4.59-4.58L18 10l-6 6l-6-6z'
											></path>
										</svg>
									</button>

									<ul
										className={`header__dropdown-menu ${
											openDropdown === index
												? 'header__dropdown-menu--open'
												: ''
										}`}
									>
										{item.dropdown.map((subItem, subIndex) => (
											<li key={subIndex} className='header__dropdown-item'>
												<Link
													to={subItem.href}
													className='header__dropdown-link'
													onClick={handleLinkClick}
												>
													{subItem.text}
												</Link>
											</li>
										))}
									</ul>
								</li>
							))}
						</ul>
						<div className='language-switcher' data-active={i18n.language}>
							<button
								onClick={() => changeLanguage('ru')}
								className={`language-switcher__button ${
									i18n.language === 'ru'
										? 'language-switcher__button--active'
										: ''
								}`}
							>
								RU
							</button>
							<div className='language-switcher__slider'></div>
							<button
								onClick={() => changeLanguage('en')}
								className={`language-switcher__button ${
									i18n.language === 'en'
										? 'language-switcher__button--active'
										: ''
								}`}
							>
								EN
							</button>
						</div>
					</nav>
				</div>
			</div>
		</header>
	);
};

export default Header;
