import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import './styles.scss';

const Members = () => {
  const { t } = useTranslation();
  const [activeTab, setActiveTab] = useState('members');
  const [activeLegislationTab, setActiveLegislationTab] = useState('rules');

  const tabs = [
    { id: 'members', title: t('members.tabs.members') },
    { id: 'legislation', title: t('members.tabs.legislation') },
    { id: 'additional', title: t('members.tabs.additional') },
  ];

  const legislationTabs = [
    { id: 'rules', title: t('members.legislation.rules') },
    { id: 'laws', title: t('members.legislation.laws') },
  ];

  const members = [
    { name: 'Royal Pure Gold', website: 'https://rpg.kg/' },
    { name: 'Omega Capital', website: 'https://omegacapital.kg/' },
    { name: 'ATO - Finance', website: 'https://ato-finance.kg' },
    { name: 'Asia Capital', website: 'https://asia-capital.kg/' },
    { name: 'Prime Capital', website: 'https://primecap.kg/' },
    { name: 'Asteri Capital', website: 'https://astericapital.kg/' },
  ];

  const linksLaws = [
    {
      text: t('members.laws.law1'),
      href: 'http://cbd.minjust.gov.kg/act/view/ru-ru/1188',
    },
    {
      text: t('members.laws.law2'),
      href: 'http://cbd.minjust.gov.kg/act/view/ru-ru/202677',
    },
    {
      text: t('members.laws.law3'),
      href: 'http://cbd.minjust.gov.kg/act/view/ru-ru/202445',
    },
    {
      text: t('members.laws.law4'),
      href: 'http://cbd.minjust.gov.kg/act/view/ru-ru/4',
    },
    {
      text: t('members.laws.law5'),
      href: 'http://cbd.minjust.gov.kg/act/view/ru-ru/5?cl=ru-ru',
    },
    {
      text: t('members.laws.law6'),
      href: 'http://cbd.minjust.gov.kg/act/view/ru-ru/667',
    },
    {
      text: t('members.laws.law7'),
      href: 'http://cbd.minjust.gov.kg/act/view/ru-ru/202105',
    },
    {
      text: t('members.laws.law8'),
      href: 'http://cbd.minjust.gov.kg/act/view/ru-ru/111486',
    },
    {
      text: t('members.laws.law9'),
      href: 'http://cbd.minjust.gov.kg/act/view/ru-ru/111522',
    },
    {
      text: t('members.laws.law10'),
      href: 'http://cbd.minjust.gov.kg/act/view/ru-ru/202621',
    },
    {
      text: t('members.laws.law11'),
      href: 'http://cbd.minjust.gov.kg/act/view/ru-ru/205090',
    },
    {
      text: t('members.laws.law12'),
      href: 'http://cbd.minjust.gov.kg/act/view/ru-ru/203321',
    },
    {
      text: t('members.laws.law13'),
      href: 'http://cbd.minjust.gov.kg/act/view/ru-ru/1190',
    },
    {
      text: t('members.laws.law14'),
      href: 'http://cbd.minjust.gov.kg/act/view/ru-ru/255',
    },
    {
      text: t('members.laws.law15'),
      href: 'http://cbd.minjust.gov.kg/act/view/ru-ru/111521',
    },
    {
      text: t('members.laws.law16'),
      href: 'http://cbd.minjust.gov.kg/act/view/ru-ru/111565/180?cl=ru-ru&mode=tekst',
    },
    {
      text: t('members.laws.law17'),
      href: 'http://cbd.minjust.gov.kg/act/view/ru-ru/205058',
    },
  ];

  const linksRegulations = [
    {
      text: t('members.rules.rule1'),
      href: 'http://cbd.minjust.gov.kg/act/view/ru-ru/12634',
    },
    {
      text: t('members.rules.rule2'),
      href: 'http://cbd.minjust.gov.kg/act/view/ru-ru/92574?cl=ru-ru#p1',
    },
    {
      text: t('members.rules.rule3'),
      href: 'http://cbd.minjust.gov.kg/act/view/ru-ru/97473',
    },
    {
      text: t('members.rules.rule4'),
      href: 'http://cbd.minjust.gov.kg/act/view/ru-ru/302442?cl=ru-ru',
    },
    {
      text: t('members.rules.rule5'),
      href: 'http://cbd.minjust.gov.kg/act/view/ru-ru/31372?cl=ru-ru',
    },
    {
      text: t('members.rules.rule6'),
      href: 'http://cbd.minjust.gov.kg/act/view/ru-ru/12873?cl=ru-ru',
    },
    {
      text: t('members.rules.rule7'),
      href: 'http://cbd.minjust.gov.kg/act/view/ru-ru/92825',
    },
    {
      text: t('members.rules.rule8'),
      href: 'http://cbd.minjust.gov.kg/act/view/ru-ru/92870',
    },
    {
      text: t('members.rules.rule9'),
      href: 'http://cbd.minjust.gov.kg/act/view/ru-ru/93100?cl=ru-ru',
    },
    {
      text: t('members.rules.rule10'),
      href: 'http://cbd.minjust.gov.kg/act/view/ru-ru/6849',
    },
    {
      text: t('members.rules.rule11'),
      href: 'http://cbd.minjust.gov.kg/act/view/ru-ru/95234?cl=ru-ru',
    },
    {
      text: t('members.rules.rule12'),
      href: 'http://cbd.minjust.gov.kg/act/view/ru-ru/92681?cl=ru-ru#p1',
    },
    {
      text: t('members.rules.rule13'),
      href: 'http://cbd.minjust.gov.kg/act/view/ru-ru/92228?cl=ru-ru#p0',
    },
    {
      text: t('members.rules.rule14'),
      href: 'http://cbd.minjust.gov.kg/act/view/ru-ru/92827?cl=ru-ru',
    },
    {
      text: t('members.rules.rule15'),
      href: 'http://cbd.minjust.gov.kg/act/view/ru-ru/92680?ckwds=%25d1%2586%25d0%25b5%25d0%25bd%25d0%25bd%25d1%258b%25d1%2585%2B%25d0%25b1%25d1%2583%25d0%25bc%25d0%25b0%25d0%25b3',
    },
    {
      text: t('members.rules.rule16'),
      href: 'http://cbd.minjust.gov.kg/act/view/ru-ru/59799?cl=ru-ru',
    },
    {
      text: t('members.rules.rule17'),
      href: 'http://cbd.minjust.gov.kg/act/view/ru-ru/59481?cl=ru-ru',
    },
    {
      text: t('members.rules.rule18'),
      href: 'http://cbd.minjust.gov.kg/act/view/ru-ru/12634?cl=ru-ru',
    },
    {
      text: t('members.rules.rule19'),
      href: 'http://cbd.minjust.gov.kg/act/view/ru-ru/95126',
    },
    {
      text: t('members.rules.rule20'),
      href: 'http://cbd.minjust.gov.kg/act/view/ru-ru/92638?cl=ru-ru',
    },
  ];

  const linksAdditionalInfo = [
    {
      text: t('members.additional.additional1'),
      href: 'https://drive.google.com/file/d/1_61QdAzJGsTNtaacpinnU_6mklqfs2q-/view?usp=sharing',
    },
    {
      text: t('members.additional.additional2'),
      href: 'https://drive.google.com/file/d/12TARM5PqUewUcomioyVy33MLkkUthf_J/view?usp=sharing',
    },
  ];

  return (
    <div className="members">
      <div className="members__tabs">
        {tabs.map((tab) => (
          <button
            key={tab.id}
            className={`members__tab ${activeTab === tab.id ? 'members__tab--active' : ''}`}
            onClick={() => setActiveTab(tab.id)}
          >
            {tab.title}
          </button>
        ))}
      </div>

      {activeTab === 'members' && (
        <div className="members__content">
          <div className="members__header">
            <span className="members__header-item">{t('members.table.name')}</span>
            <span className="members__header-item">{t('members.table.website')}</span>
          </div>
          {members.map((participant, index) => (
            <div key={index} className="members__row">
              <span className="members__name">{participant.name}</span>
              <a
                target="_blank"
                rel="noopener noreferrer"
                href={participant.website}
                className="members__website"
              >
                {t('members.table.visitWebsite')}
              </a>
            </div>
          ))}
        </div>
      )}

      {activeTab === 'legislation' && (
        <div className="legislation">
          <div className="legislation__tabs">
            {legislationTabs.map((tab) => (
              <button
                key={tab.id}
                className={`legislation__tab ${
                  activeLegislationTab === tab.id ? 'legislation__tab--active' : ''
                }`}
                onClick={() => setActiveLegislationTab(tab.id)}
              >
                {tab.title}
              </button>
            ))}
          </div>
          {activeLegislationTab === 'rules' && (
            <ul className="legislation__list">
              {linksRegulations.map((rule, index) => (
                <li key={index} className="legislation__item">
                  <a href={rule.href} target="_blank" rel="noopener noreferrer">
                    {rule.text}
                  </a>
                </li>
              ))}
            </ul>
          )}
          {activeLegislationTab === 'laws' && (
            <ul className="legislation__list">
              {linksLaws.map((law, index) => (
                <li key={index} className="legislation__item">
                  <a href={law.href} target="_blank" rel="noopener noreferrer">
                    {law.text}
                  </a>
                </li>
              ))}
            </ul>
          )}
        </div>
      )}

      {activeTab === 'additional' && (
        <ul className="legislation__list">
          {linksAdditionalInfo.map((info, index) => (
            <li key={index} className="legislation__item">
              <a href={info.href} target="_blank" rel="noopener noreferrer">
                {info.text}
              </a>
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default Members;
