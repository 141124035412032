import React, { useState, useRef, useEffect } from 'react';
import './styles.scss';
import DocImg1 from '../../../images/about/documents/doc1.png';
import DocImg2 from '../../../images/about/documents/doc2.png';
import DocImg3 from '../../../images/about/documents/doc3.png';
import DocImg4 from '../../../images/about/documents/doc4.png';
import DocImg5 from '../../../images/about/documents/doc5.png';
import DocImg6 from '../../../images/about/documents/doc6.png';
import DocImg7 from '../../../images/about/documents/doc7.png';
import { useTranslation } from 'react-i18next';

const AboutDocuments = () => {
	const { t } = useTranslation();
	const [activeTab, setActiveTab] = useState('justice_certificate');
	const tabsRef = useRef(null);

	const documents = {
		justice_certificate: {
			image: DocImg1,
			alt: t('about_documents.content.justice_certificate.title'),
			link: 'https://drive.google.com/file/d/1vwYfiGAZVzmj26UGXFml7R1V63_5oRLT/view?usp=sharing',
		},
		trading_license: {
			image: DocImg2,
			alt: t('about_documents.content.trading_license.title'),
			link: 'https://drive.google.com/file/d/1i9lOO1ihwLbM3mdwOng4dERBgc4E6ZXR/view?usp=sharing',
		},
		depository_license: {
			image: DocImg3,
			alt: t('about_documents.content.depository_license.title'),
			link: 'https://drive.google.com/file/d/1iPlqSWsE-NPmX91TAmGBMhlXxg5fxWff/view?usp=sharing',
		},
		trading_operator_license: {
			image: DocImg4,
			alt: t('about_documents.content.trading_operator_license.title'),
			link: 'https://drive.google.com/file/d/1Gr2sMavdoFe5Kx7pDgs3vBVEDTSfh2rs/view?usp=sharing',
		},
		exchange_operator_license: {
			image: DocImg5,
			alt: t('about_documents.content.exchange_operator_license.title'),
			link: 'https://drive.google.com/file/d/11MAkTprtxowNNKV5yeRtOJWV758m9iKq/view?usp=sharing',
		},
		kyrgyzpatent_certificate: {
			image: DocImg6,
			alt: t('about_documents.content.kyrgyzpatent_certificate.title'),
			link: 'https://drive.google.com/file/d/1do2mYUWtsC5Nb4tV9hx414PBiBA5Gmp4/view?usp=sharing',
		},
		chamber_of_commerce_certificate: {
			image: DocImg7,
			alt: t('about_documents.content.chamber_of_commerce_certificate.title'),
			link: 'https://drive.google.com/file/d/1DfEtUXHErjcUSUKwndXrr5CcsFf6arT8/view?usp=sharing',
		},
	};

	const handleTabClick = (tab) => {
		setActiveTab(tab);
		const tabElement = tabsRef.current.querySelector(`[data-tab="${tab}"]`);
		if (tabElement) {
			tabElement.scrollIntoView({
				behavior: 'smooth',
				block: 'nearest',
				inline: 'center',
			});
		}
	};

	return (
		<div className='about_documents'>
			<h2 className='about_documents__title title'>{t('about_documents.title')}</h2>
			<div className='about_documents__container'>
				<div className='about_documents__tabs-container'>
					<div className='about_documents__tabs' ref={tabsRef}>
						{Object.keys(documents).map((tab) => (
							<button
								key={tab}
								data-tab={tab}
								className={`about_documents__tab ${
									activeTab === tab ? 'about_documents__tab--active' : ''
								}`}
								onClick={() => handleTabClick(tab)}
							>
								{t(`about_documents.tabs.${tab}`)}
							</button>
						))}
					</div>
				</div>
				<div className='about_documents__content-wrapper'>
					<div className='about_documents__content'>
						<div className='about_documents__content-image-wrapper'>
							<img
								src={documents[activeTab].image}
								alt={documents[activeTab].alt}
								className='about_documents__content-image'
							/>
						</div>
						<div className='about_documents__content-info'>
							<h3 className='about_documents__content-title'>
								{t(`about_documents.content.${activeTab}.title`)}
							</h3>
							<a
								target='_blank'
								rel='noopener noreferrer'
								href={documents[activeTab].link}
								className='about_documents__content-button'
							>
								{t(`about_documents.content.${activeTab}.link_text`)}
							</a>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default AboutDocuments;
